import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useAuth } from "../hook/useAuth";
import { Form, Input, Button } from "antd";
import { login } from "../components/api/db-api";
import { message } from "antd";
import "../UI/css/Loginpage.css";

const Loginpage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signIn } = useAuth();
  if (user) {
    message.success("Ви вже авторизовані!");
    return <Navigate to="/"></Navigate>;
  }
  const fromPage = location.state?.from?.pathname || "/";

  const onFinish = async (values) => {
    const username = values.username;
    const password = values.password;
    try {
      const result = await login(username, password);
      if (result.status === "success") {
        const user = { username: "admin" };
        sessionStorage.setItem("user", JSON.stringify(user));
        signIn(user, () => navigate(fromPage, { replace: true }));
      } else {
        return message.error(result.message);
      }
    } catch (error) {
      return message.error("Невідома помилка...");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="loginPage">
        <Form
          className="loginForm"
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Логін"
            name="username"
            rules={[
              {
                required: true,
                message: "Введіть логін!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: "Введіть пароль!",
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item>
            <Button size="large" block type="primary" htmlType="submit">
              Вхід
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Loginpage;

import { getServer } from "../../utils/Environment.ts";

const server = getServer();

export const createFtpUser = async (login, password, homedir) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      method: "create-ftp-user",
      login,
      password,
      homedir,
    }),
  });
  try {
    return await responce.json();
  } catch (error) {
    console.log(error);
    return false;
  }
};

import React, { useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  message,
} from "antd";
import dayjs from "dayjs";
import { createUser, getCompanies } from "../api/db-api";
import { useNavigate } from "react-router";

export default function CreateForm() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    getData();

    form.setFieldsValue({ deviceCount: 1, password: "", login: "" });
  }, []);

  const getData = async () => {
    const companies = await getCompanies();
    console.log(companies);
  };

  const onFinish = async (values) => {
    const result = await createUser(values);

    if (result?.status === "success") {
      message.success("Успішно!");
      navigate("/", { state: { updateTable: true } });
    } else {
      message.error("Помилка додавання користувача. " + result?.message + "");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  return (
    <>
      <Row justify={"center"}>
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          wrapperCol={{
            span: 20,
          }}
          form={form}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="Логін"
                name="login"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Пароль"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Компанія"
                name="company"
                rules={[
                  {
                    required: true,
                    message: "Введіть компанію",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="expirationDate"
                label="Доступ до"
              >
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item name="deviceCount" label="К-ть пристроїв">
                <InputNumber min={1} defaultValue={1}></InputNumber>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  Створити
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
}

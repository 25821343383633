import { Modal, List, message } from "antd";
import { clearUserDevices } from "../api/db-api";
// import { createFtpUser } from "../api/hosting-api/functions";

export const ModalActions = (props: any) => {
  const handleCancel = () => {
    props.setIsModalActionsOpen(false);
  };

  const handleMenuClick = async (record: any) => {
    switch (record.key) {
      case "createFtpUser":
        // const result = await createFtpUser(props.user.id);
        // console.log(result);
        break;
      case "clearDevices":
        const deleted = await clearUserDevices(props.user.id, props.user.login);
        // const res = deleted === "1" ? true : false;
        if (deleted) {
          message.success("Пристрої очищено!");
          let updatedUser = props.user;
          updatedUser.used_devices = 0;
          console.log(updatedUser);
          props.setUsers([...props.users], updatedUser);
        } else {
          message.error("Помилка очищення пристроїв");
        }
        props.setIsModalActionsOpen(false);
        break;
      case "edit":
        props.setIsModalActionsOpen(false);
        props.setIsModalEditUserOpen(true);
        break;
      case "delete":
        message.error("Так собі ідея");
        break;
      default:
        break;
    }
  };

  const data = [
    {
      title: "Змінити",
      key: "edit",
    },
    {
      title: "Очистити пристрої",
      key: "clearDevices",
    },
    {
      title: "Створити користувача FTP (UKRAINE HOSTING)",
      key: "createFtpUser",
    },
    {
      title: "Видалити",
      key: "delete",
    },
  ];

  return (
    <>
      <Modal
        title={"Користувач " + props.user?.login}
        style={{ textAlign: "center" }}
        open={props.isModalActionsOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item onClick={(e) => handleMenuClick(item)}>
              {item.title}
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};
export default ModalActions;

import {
  BarChartOutlined,
  HomeOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Menu, Modal, message } from "antd";
import React, { useState } from "react";
import { clearAllDevices } from "../api/db-api";
import { useNavigate } from "react-router";
import { useAuth } from "../../hook/useAuth";

const handleClearDevices = () => {
  Modal.confirm({
    title: "Очистити таблицю пристроїв?",
    onCancel: () => {
      return;
    },
    onOk: async () => {
      const success = await clearAllDevices();
      console.log(success);
    },
  });
};

const items = [
  {
    label: "Користувачі WAAPP",
    key: "home",
    icon: <HomeOutlined />,
  },
  {
    label: "Статистика WAAPP",
    key: "waapp-statistics",
    icon: <BarChartOutlined />,
  },
  {
    label: "Торгові агенти",
    key: "tradeAgents",
    icon: <UserSwitchOutlined />,
  },
  {
    label: "Інше",
    key: "SubMenu",
    icon: <SettingOutlined />,
    children: [
      {
        label: "Очистити всі пристрої",
        key: "clearDevices",
      },
    ],
  },
  {
    label: "Вихід",
    key: "logout",
    icon: <LogoutOutlined />,
  },
];
export default function HeadMenu() {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const [current, setCurrent] = useState("mail");
  const onClick = (e) => {
    // console.log("click ", e);
    setCurrent(e.key);
    switch (e.key) {
      case "clearDevices":
        handleClearDevices();
        break;
      case "waapp-statistics":
        navigate("waapp-statistics");
        break;
      case "tradeAgents":
        navigate("trade-agents");
        break;
      case "home":
        navigate("/");
        break;
      case "logout":
        signOut(() => {
          message.success("Ви вийшли з системи!");
        });
        break;
      default:
        break;
    }
  };
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
    />
  );
}

import { useEffect, useState } from "react";
import AgentsTable from "../components/trade-agents/AgentsTable";
import { getTradeAgents } from "../components/api/db-api";

export default function TradeAgents() {
  const [tradeAgents, setTradeAgents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const usersFromDb = await getTradeAgents();
    const users = usersFromDb.map((user: { key: any }, index: number) => {
      user.key = index + 1;
      return user;
    });
    setTradeAgents(users);
    setLoading(false);
  };

  return (
    <>
      <AgentsTable
        loading={loading}
        tradeAgents={tradeAgents}
        setTradeAgents={setTradeAgents}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import EditForm from "../users/EditForm";
import SettingsForm from "../users/SettingsForm";
import { Modal, Spin, Tabs } from "antd";
import { getUserById } from "../api/db-api";
import { User } from "../../CONSTANTS/interfaces";

interface ModalEditUserProps {
  user: User;
  isModalEditUserOpen: boolean;
  setIsModalEditUserOpen: Function;
  setUsers: Function;
}

export default function ModalEditUser({
  user,
  isModalEditUserOpen,
  setIsModalEditUserOpen,
  setUsers,
}: ModalEditUserProps) {
  const [editingUser, setEditingUser] = useState<any>();

  useEffect(() => {
    if (user) getData(user?.id);

    return () => {
      setEditingUser(undefined);
    };
  }, [user?.id]);

  const getData = async (id: number) => {
    const userObj = await getUserById(id);
    setEditingUser(userObj);
  };

  const handleCancel = () => {
    setIsModalEditUserOpen(false);
  };

  const items = [
    {
      key: "1",
      label: `Дані`,
      children: <EditForm user={editingUser} setUsers={setUsers}></EditForm>,
    },
    {
      key: "2",
      label: `Налаштування`,
      children: (
        <SettingsForm user={editingUser} setUsers={setUsers}></SettingsForm>
      ),
    },
  ];

  if (!editingUser) return <Spin spinning={true}></Spin>;

  return (
    <div>
      <Modal
        title={"Користувач " + user?.login}
        open={isModalEditUserOpen}
        styles={{ header: { textAlign: "center" } }}
        footer={null}
        onCancel={handleCancel}
        width={"90%"}
      >
        <Tabs defaultActiveKey="1" items={items}></Tabs>
      </Modal>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { getStatistics } from "../components/api/db-api";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { Table, Input, Space, Button } from "antd";

export default function WaappStatistics() {
  const [setSearchText] = useState();
  const searchInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [statisticsData, setStatisticsData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const statistics = await getStatistics();
    setStatisticsData(statistics);
    setLoading(false);
  };

  const handleSearch = (selectedKeys: Array<string>, confirm: Function) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          size={"large"}
          ref={searchInput}
          placeholder={`Введіть текст`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Очистити
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: <UserOutlined />,
      dataIndex: "username",
      key: "username",
      align: "center",
      sorter: (a, b) => {
        return a.username.localeCompare(b.username);
      },
      ...getColumnSearchProps("username"),
    },
    {
      title: "Ревізії",
      dataIndex: "revizia",
      key: "revisions",
      align: "center",
      sorter: (a, b) => a.revizia - b.revizia,
    },
    {
      title: "Приходи",
      dataIndex: "invoice",
      key: "invoices",
      align: "center",
      sorter: (a, b) => a.invoice - b.invoice,
    },
    {
      title: "Замовлення",
      dataIndex: "order",
      key: "orders",
      align: "center",
      sorter: (a, b) => a.order - b.order,
    },
    {
      title: "Повернення",
      dataIndex: "returns",
      align: "center",
      sorter: (a, b) => a.returns - b.returns,
    },
    {
      title: "Переміщення",
      dataIndex: "diplacements",
      align: "diplacements",
      sorter: (a, b) => a.diplacements - b.diplacements,
    },
    {
      title: "Реалізації",
      dataIndex: "realization",
      key: "realizations",
      align: "center",
      sorter: (a, b) => a.realization - b.realization,
    },
    {
      title: "Перевірки цін",
      dataIndex: "checkPrice",
      key: "checkPrices",
      align: "center",
      sorter: (a, b) => a.checkPrice - b.checkPrice,
    },
    {
      title: "Перевірки документів",
      dataIndex: "verification",
      key: "verifications",
      align: "center",
      sorter: (a, b) => a.verification - b.verification,
      render: (_, record) => {
        return record?.verification;
      },
    },
  ];

  const tableSummary = (pageData) => {
    const servicesArray = [
      {
        title: "realization",
        value: 0,
      },
      {
        title: "verification",
        value: 0,
      },
      {
        title: "revizia",
        value: 0,
      },
      {
        title: "diplacements",
        value: 0,
      },
      {
        title: "order",
        value: 0,
      },
      {
        title: "incoice",
        value: 0,
      },
      {
        title: "returns",
        value: 0,
      },
      {
        title: "checkPrices",
        value: 0,
      },
    ];

    pageData.forEach((element) => {
      // delete element.username;

      const userServicesData = Object.keys(element);
      userServicesData.forEach((userService) => {
        const serviceValue = element[userService];
        servicesArray.find((serv) => {
          if (serv.title === userService) {
            serv.value = +serv.value + +serviceValue;
          }
        });
      });
    });

    return (
      <>
        <Table.Summary.Row
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          <Table.Summary.Cell index={0}>Всього</Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            {
              servicesArray.find((service) => service.title === "revizia")
                ?.value
            }
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            {
              servicesArray.find((service) => service.title === "invoice")
                ?.value
            }
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            {servicesArray.find((service) => service.title === "order")?.value}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            {
              servicesArray.find((service) => service.title === "returns")
                ?.value
            }
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            {
              servicesArray.find((service) => service.title === "diplacements")
                ?.value
            }
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            {
              servicesArray.find((service) => service.title === "realization")
                ?.value
            }
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7}>
            {
              servicesArray.find((service) => service.title === "checkPrice")
                ?.value
            }
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            {
              servicesArray.find((service) => service.title === "verification")
                ?.value
            }
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const onRowAction = (record, rowIndex) => {
    return {
      onClick: (event) => {
        // setIsModalActionsOpen(true);
        // setUser(record);
      },
    };
  };

  return (
    <>
      <Table
        scroll={{
          y: window.screen.height - window.screen.height / 3,
        }}
        summary={tableSummary}
        loading={loading}
        onRow={onRowAction}
        columns={columns}
        dataSource={statisticsData}
        bordered={true}
        pagination={{ position: ["bottomCenter"], showSizeChanger: true }}
      />
    </>
  );
}
